import { Features } from './constants';

import disableFeaturesByDeviceContraints from './disableFeaturesByDeviceContraints';

const createFeatureDecisions = async featureToggle => {
  await disableFeaturesByDeviceContraints(featureToggle.disableFeature);

  return {
    fullscreenEnabled: featureToggle.isFeatureEnabled(Features.FULLSCREEN),
    autoRotateEnabled: featureToggle.isFeatureEnabled(Features.AUTO_ROTATE),
    vrModeEnabled: featureToggle.isFeatureEnabled(Features.VR_MODE),
    gyroscopeEnabled: featureToggle.isFeatureEnabled(Features.GYROSCOPE),
    apartmentRatingEnabled: featureToggle.isFeatureEnabled(null),
    captureScreenshotEnabled: featureToggle.isFeatureEnabled(null),
    socialMediaShareEnabled: featureToggle.isFeatureEnabled(null),
    dollhouseEnabled: featureToggle.isFeatureEnabled(null),
    turntableDollhouse: featureToggle.isFeatureEnabled(null),
  };
};

export default createFeatureDecisions;
