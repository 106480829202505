const createFeatureToggle = featureConfig => {
  return {
    isFeatureEnabled (featureName) {
      return featureConfig[featureName];
    },
    enableFeature (featureName) {
      featureConfig[featureName] = true;
    },
    enableAllFeatures () {
      Object.keys(featureConfig).forEach(featureName => this.enableFeature(featureName));
    },
    disableFeature (featureName) {
      featureConfig[featureName] = false;
    },
    disableAllFeatures () {
      Object.keys(featureConfig).forEach(featureName => this.disableFeature(featureName));
    },
  };
};

export default createFeatureToggle;
