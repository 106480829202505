var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (JSON, cubemapData, defaultRoomsStatus, hotspots, spheres, translations, window) {
      const cubemapDataJSON = JSON.stringify(cubemapData)
const roomStatus = JSON.stringify(defaultRoomsStatus)
const hotspotsJSON = JSON.stringify(hotspots)
const sphereDataJSON = JSON.stringify(spheres)
let tr = translations
const alterLocalhost = window.location.host.includes('192.168');
const localHost = window.location.host.includes('0.0.0.0');
{
if (localHost || alterLocalhost) {
pug_html = pug_html + "\u003Clink rel=\"stylesheet\" href=\"\u002Fdist\u002Fstyle.css\"\u003E\u003Clink rel=\"shortcut icon\" type=\"image\u002Fpng\" href=\"\u002Fdist\u002Fassets\u002Fimages\u002Flogo.png\"\u003E";
}
else {
pug_html = pug_html + "\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ftour-viewer.azureedge.net\u002Fdist\u002Fstyle.css\"\u003E\u003Clink rel=\"shortcut icon\" type=\"image\u002Fpng\" href=\"https:\u002F\u002Ftour-viewer.azureedge.net\u002Fdist\u002Fassets\u002Fimages\u002Flogo.png\"\u003E";
}
}
pug_html = pug_html + "\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Lato:wght@300;400;700;900&amp;display=swap\"\u003E";
    }.call(this, "JSON" in locals_for_with ?
        locals_for_with.JSON :
        typeof JSON !== 'undefined' ? JSON : undefined, "cubemapData" in locals_for_with ?
        locals_for_with.cubemapData :
        typeof cubemapData !== 'undefined' ? cubemapData : undefined, "defaultRoomsStatus" in locals_for_with ?
        locals_for_with.defaultRoomsStatus :
        typeof defaultRoomsStatus !== 'undefined' ? defaultRoomsStatus : undefined, "hotspots" in locals_for_with ?
        locals_for_with.hotspots :
        typeof hotspots !== 'undefined' ? hotspots : undefined, "spheres" in locals_for_with ?
        locals_for_with.spheres :
        typeof spheres !== 'undefined' ? spheres : undefined, "translations" in locals_for_with ?
        locals_for_with.translations :
        typeof translations !== 'undefined' ? translations : undefined, "window" in locals_for_with ?
        locals_for_with.window :
        typeof window !== 'undefined' ? window : undefined));
    ;;return pug_html;};
module.exports = template;