import { Features } from './constants';

export default {
  [Features.FULLSCREEN]: true,
  [Features.AUTO_ROTATE]: true,
  [Features.VR_MODE]: true,
  [Features.GYROSCOPE]: true,
  [Features.APARTMENT_RATING]: false,
  [Features.CAPTURE_SCREENSHOT]: false,
  [Features.SOCIAL_MEDIA_SHARE]: false,
  [Features.DOLLHOUSE]: false,
  [Features.TURNTABLE_DOLLHOUSE]: false,
};
