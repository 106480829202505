import pugBodyTemplate from './views/showTourBody.pug';
import pugHeadTemplate from './views/showTourHead.pug';
import { getTranslation } from './services/translations/getTranslations';

import {
  featureToggle,
  createFeatureDecisions,
} from './features';

const _500_ERROR_INFO = () => {
  const error = document.createElement('div');
  error.style.transition = 'opacity 300ms ease-in-out';
  error.style.opacity = '0';
  error.innerHTML = `<div class="error-wrapper" style="font-family: sans-serif; background-color: #f3f3f3; padding: 1em; max-width: 520px; position: fixed; border-radius: 10px; left: 50%; top: 50%; transform: translate(-50%, -50%); color: #1b1b1b;">
  <h3 style="font-weight: 400; text-align: center">Wystąpił błąd podczas ładowania spaceru, spróbuj ponownie, jeśli to nie pomoże, prawdopodobnie mamy chwilowe problemy z serwerem</h3>
  <p style="text-align: center">Możesz również poinformować nas pod adresem email: <a href="mailto:support@3destate.pl" target="_blank">support@3destate.pl</a></p>
  <button onclick="location.reload()" style="margin: auto; display: block; padding: 0.8em 2em; border: none; cursor: pointer; outline: none;background-color: #fff; border-radius: 6px; box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;">Odśwież stronę</button>
</div>`;

  document.body.appendChild(error);
  setTimeout(() => {
    error.style.opacity = '1';
  }, 600);
};

class Loader3DEstate {
  constructor (loaderUrl, templateBody = pugBodyTemplate, templateHead = pugHeadTemplate) {
    this.loaderUrl = loaderUrl;

    if (!this.scriptDomain) {
      this.scriptDomain = location.origin; // dev env
    }

    this.templateBody = templateBody;
    this.templateHead = templateHead;
    this.fetchConfig(loaderUrl);
  }

  fetchConfig (url) {
    if (getTranslation().language !== 'pl') {
      document.title = 'Virtual Tour 3D Estate';
    } else {
      document.title = 'Wirtualny Spacer 3D Estate';
    }

    const self = this;
    let dataset;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        version: 2.0,
      },
    })
      .then(response => {
        if (response.status >= 500) {
          _500_ERROR_INFO();
          const err = response.statusText || 'Failed to fetch';
          return Promise.reject(err);
        }
        return response.json();
      })
      .then(data => {
        dataset = data;
        return generateLoader(dataset);
      })
      .then((config) => {
        this.loadView(config);
      }).catch((error) => {
        console.error(error);
      });

    function generateLoader (dataset) {
      const { tourCameraConfigModel, tourOptionsModel, interiorDesignerPreset } = dataset;
      let keyNum = 0;
      let defaultRooms = {};

      tourCameraConfigModel.miniMap.forEach(({ miniMapLinks }) => {
        miniMapLinks.forEach(() => {
          keyNum++;
          defaultRooms = { ...defaultRooms, [keyNum]: { status: 'loading' } };
        });
      });

      const loader = {
        tourCameraConfigModel,
        tourOptionsModel,
        interiorDesignerPreset,
        defaultRoomsStatus: { ...defaultRooms },
      };
      loader.scriptDomain = self.scriptDomain;
      return loader;
    }
  }

  loadView (config) {
    const { templateBody, templateHead } = this;

    document.head.innerHTML += templateHead(config);
    const scriptDomain = this.scriptDomain;

    const localHost = window.location.host.includes('0.0.0.0');
    const alterLocalHost = window.location.host.includes('192.168');
    const scriptsGA = (localHost || alterLocalHost) ? (scriptDomain + '/dist/GASetup.js') : 'https://tour-viewer.azureedge.net/dist/GASetup.js';
    const scriptsComponents = (localHost || alterLocalHost) ? (scriptDomain + '/dist/Components.bundle.js') : 'https://tour-viewer.azureedge.net/dist/Components.bundle.js';

    const scripts = [
      scriptsGA,
      'https://aframe.io/releases/1.3.0/aframe.min.js',
      scriptsComponents,
    ];

    function importScripts (scriptsToLoad, callback) {
      if (scriptsToLoad.length === 0) {
        if (callback !== undefined) {
          callback(null);
        }
      }
      var i = 0;
      var s;
      var r;
      var e;
      var l;
      e = function (event) {
        s.parentNode.removeChild(s);
        if (callback !== undefined) {
          callback(event);
        }
      };
      l = function () {
        s.parentNode.removeChild(s);
        i++;
        if (i < scriptsToLoad.length) {
          r();
          return;
        }
        if (callback !== undefined) {
          callback(null);
        }
      };
      r = function () {
        s = document.createElement('script');
        s.src = scriptsToLoad[i];
        s.onerror = e;
        s.onload = l;
        document.head.appendChild(s);
      };
      r();
    }

    const asynchronousBuild = async function buildContent () {
      const translations = getTranslation();
      const featureDecisions = await createFeatureDecisions(featureToggle);

      document.body.style.opacity = '1';
      const bodyContent = {
        ...config,
        translations,
        featureDecisions,
      };
      document.body.innerHTML = templateBody(bodyContent);
    };

    importScripts(scripts, asynchronousBuild);
  }
}

window.Loader3DEstate = Loader3DEstate;
