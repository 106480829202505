import { translations } from './translations';

export const getTranslation = () => {
  const browserLang = (navigator.language || navigator.userLanguage || '').slice(0, 2) || '';
  const availableLanguages = ['pl', 'en', 'de', 'fr', 'it', 'he', 'es', 'bg'];

  const searchParams = new URLSearchParams(window.location.search);
  const getLangParam = searchParams.get('lang');

  if (availableLanguages.includes(getLangParam)) {
    return translations[getLangParam];
  }
  return translations[browserLang] || translations.en;
};
