export const Features = {
  FULLSCREEN: 'fullscreen',
  AUTO_ROTATE: 'auto-rotate',
  VR_MODE: 'vr-mode',
  GYROSCOPE: 'gyroscope',
  APARTMENT_RATING: 'apartment-rating',
  CAPTURE_SCREENSHOT: 'capture-screenshot',
  SOCIAL_MEDIA_SHARE: 'social-media-share',
  DOLLHOUSE: 'dollHouse',
  TURNTABLE_DOLLHOUSE: 'turntableDollHouse',
};
