import {
  isDesktop,
  isVRCompatible,
  isMobileVR,
  isGyroscopeSupported,
  isIpadNewOs, isIPad, isIOS,
} from '../utils/device';

import { Features } from './constants';

export default async (disableFeature) => {
  const supportsFullscreen = document.body.requestFullscreen ||
    document.body.webkitRequestFullscreen ||
    document.body.mozRequestFullScreen;

  const supportsGyroscope = await isGyroscopeSupported();
  const gyroscopeSupportViaLocalStorage = document.cookie && sessionStorage.getItem('gyroscopeCheck');

  const mobileVR = isMobileVR();

  if (!supportsFullscreen || mobileVR) {
    disableFeature(Features.FULLSCREEN);
  }

  if (!isDesktop() || isIpadNewOs()) {
    disableFeature(Features.AUTO_ROTATE);
  }

  if (!supportsGyroscope && gyroscopeSupportViaLocalStorage !== 'ON') {
    disableFeature(Features.GYROSCOPE);
  }

  if (window.location.href.includes('isExterior')) {
    disableFeature(Features.FULLSCREEN);
    disableFeature(Features.TURNTABLE_DOLLHOUSE);
    disableFeature(Features.DOLLHOUSE);
    disableFeature(Features.APARTMENT_RATING);
  }

  if (isIpadNewOs() || isIPad() || isIOS()) {
    disableFeature(Features.VR_MODE);
  }

  if ((gyroscopeSupportViaLocalStorage !== 'ON' && !supportsGyroscope) || !isVRCompatible()) {
    disableFeature(Features.VR_MODE);
  }
};
