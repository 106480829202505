export const translations = {
  en: {
    language: 'en',
    floor: 'FLOOR',
    poweredBy: 'Created by',
    visit: 'visit our website',
    cookies: 'The application uses cookies. Cookies are used only to keep the application running, analyzing the way the application is used and its individual functionalities and to adjust the application to user preferences and the further development of its functionality, we do not track traffic or profile individual user behavior on the Internet.',
    floor_plan: 'FLOOR PLAN',
    multi_styles: 'CHOOSE STYLE',
    dollhouse: 'DOLLHOUSE',
    share: 'SHARE',
    rate: 'RATE',
    vr_mode: 'VR MODE',
    auto_rotate: 'AUTO-ROTATE',
    gyroscope: 'GYROSCOPE',
    fullscreen: 'FULLSCREEN',
    screenshot: 'SCREENSHOT',
    guide_decoroom: 'Presented apartment design has been created in cooperation with Warsaw based Decoroom Sp. z o.o. and is a proposal of possible arrangement and turnkey finishing of your apartment by Decoroom.',
    guide_sub_decoroom: 'For more information, see ',
    guide_title2: 'The presented tour is a visualization',
    guide_title: 'How to use the tour?',
    move_keyboard: 'Look around the apartment by using mouse or keyboard arrows',
    move_finger: 'Look around the apartment by sliding your finger on the screen or turning device',
    next_room: 'To see the next room, click on the white icon',
    loading: 'Loading',
    disclaimer: 'The information contained on the site and site itself does not constitute an offer for the purposes of the Civil Code',
    error_no_webgl: 'Your device doesn\'t seem to support WebGL. Find out how to get it ',
    error_context_lost: 'Please try again by refreshing your browser',
    something_wrong: 'Something went wrong',
    webgl_link: 'here',
    share_to_social_title: 'Share apartment tour',
    share_to_social_description: 'Share your apartment tour with friends on social media or ',
    share_to_social_description_span: 'copy link',
    rate_apartment_title: 'How do you like this apartment?',
    rate_apartment_description: 'Share your opinion about the apartment by rating it on a scale of 1 to 5',
    rate_apartment_thankyou_title: 'Thank you!',
    rate_apartment_thankyou_description: 'Your opinion means a lot to us',
    shoppingList: {
      designer: 'INTERIOR DESIGN BY',
      all: 'All',
      more: 'More',
      generatePDF: 'Generate product list (PDF)',
      downloadPDF: 'Download product list (PDF)',
      generatingList: 'Generating list',
      summaryPrice: 'Furnishing total price',
      allSelect: 'All (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'To use the virtual reality, please click the button below:',
        permission_button: 'GYROSCOPE',
        toggle_gyroscope: 'You can turn the gyroscope on and off at any time by clicking the gyroscope button on the bottom of the screen.',
        cancel_permission: 'You recently canceled gyroscope access, if you want to change that, close and reopen the browser',
        not_supported: 'Your iPhone has a damaged gyroscope or is not supported by the application',
      },
    },
    roomsTitle: {
      livingRoom: 'LIVING ROOM',
      bedroom: 'BEDROOM',
      bathroom: 'BATHROOM',
      moveStraight: 'GO STRAIGHT',
      childrenRoom: 'ROOM',
      kitchen: 'KITCHEN',
      stairs: 'STAIRS',
      toilet: 'TOILET',
      bathroom2: 'BATHROOM',
      exterior: 'GO OUTSIDE',
      exterior2: 'GO FURTHER',
      dressingRoom: 'DRESSING ROOM',
      pantry: 'PANTRY',
      retailSpace: 'RETAIL SPACE',
      boilerRoom: 'BOILER ROOM',
      homeOffice: 'HOME OFFICE',
      pool: 'POOL',
      washingRoom: 'WASHING ROOM',
      winterGarden: 'WINTER GARDEN',
      entresol: 'ENTRESOL',
      attic: 'ATTIC',
      library: 'LIBRARY',
      gameRoom: 'GAME ROOM',
      homeTheater: 'HOME THEATER',
      playRoom: 'PLAY ROOM',
      workShop: 'WORK SHOP',
      livingRoomWithAKitchenette: 'LIVING ROOM WITH A KITCHENETTE',
      habitableAttic: 'HABITABLE ATTIC',
      utilityRoom: 'UTILITY ROOM',
      service: 'SERVICE',
      diningRoom: 'DINING ROOM',
    },
  },
  pl: {
    language: 'pl',
    floor: 'POZIOM',
    poweredBy: 'Stworzone przez',
    visit: 'odwiedź stronę',
    cookies: 'Aplikacja stosuje pliki cookies. Pliki cookies stosowane są wyłącznie w celu dostarczania i utrzymywania aplikacji w ruchu, analizy sposobu wykorzystania aplikacji i jej poszczególnych funkcjonalności oraz w celu dostosowywania aplikacji do preferencji użytkowników oraz dalszego rozwoju jej funkcjonalności, nie śledzimy ruchu ani nie profilujemy zachowań poszczególnych użytkowników w Internecie.',
    floor_plan: 'PLAN',
    multi_styles: 'WYBIERZ STYL',
    dollhouse: 'DOLLHOUSE',
    share: 'UDOSTĘPNIJ',
    rate: 'OCEŃ',
    vr_mode: 'TRYB VR',
    auto_rotate: 'AUTOOBRÓT',
    gyroscope: 'ŻYROSKOP',
    fullscreen: 'PEŁNY EKRAN',
    screenshot: 'ZRZUT EKRANU',
    guide_decoroom: 'Prezentowany projekt lokalu powstał we współpracy z Decoroom sp. z o.o. z siedzibą w Warszawie i stanowi propozycję możliwości aranżacji i wykończenia pod klucz Twego mieszkania przez Decoroom. ',
    guide_sub_decoroom: 'Więcej informacji znajdziesz na ',
    guide_title2: 'Prezentowana wycieczka jest wizualizacją',
    guide_title: 'Jak korzystać z wycieczki?',
    move_keyboard: 'Poruszaj się za pomocą myszki lub strzałek na klawiaturze',
    move_finger: 'Poruszaj się przesuwając palcem po ekranie lub obracając urządzenie',
    next_room: 'Aby przejść do kolejnego pomieszczenia, kliknij w jego ikonę',
    loading: 'Trwa ładowanie',
    disclaimer: 'Przedstawiona aranżacja lokalu jest przykładowa, a widoki zewnętrzne mogą różnić się od rzeczywistych. Wizualizacje w tym zakresie mają charakter poglądowy oraz informacyjny i nie stanowią oferty w rozumieniu Kodeksu Cywilnego.',
    something_wrong: 'Coś poszło nie tak',
    error_no_webgl: 'Twoje urządzenie nie wspiera WebGL. Dowiedz się więcej',
    webgl_link: 'tutaj',
    error_context_lost: 'Spróbuj ponownie odświeżając przeglądarkę',
    share_to_social_title: 'Udostępnij wycieczkę',
    share_to_social_description: 'Podziel się wycieczką ze znajomymi w mediach społecznościowych lub ',
    share_to_social_description_span: 'skopiuj link',
    rate_apartment_title: 'Jak podoba Ci się to mieszkanie?',
    rate_apartment_description: 'Podziel się swoją opinią na temat mieszkania oceniając je w skali od 1 do 5',
    rate_apartment_thankyou_title: 'Dziękujemy!',
    rate_apartment_thankyou_description: 'Twoja opinia dużo dla nas znaczy',
    shoppingList: {
      designer: 'PROJEKT WNĘTRZA',
      all: 'Wszystkie',
      more: 'Więcej',
      generatePDF: 'Wygeneruj listę produktów (PDF)',
      downloadPDF: 'Pobierz listę produktów (PDF)',
      generatingList: 'Trwa generowanie listy',
      summaryPrice: 'Łączna cena wyposażenia',
      allSelect: 'Wszystkie (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'Aby skorzystać z funkcji wirtualnej rzeczywistości, kliknij przycisk poniżej:',
        permission_button: 'ŻYROSKOP',
        toggle_gyroscope: 'Możesz włączyć i wyłączyć żyroskop w każdym momencie, klikając na przycisk żyroskopu na dole ekranu.',
        cancel_permission: 'Anulowałeś dostęp do żyroskopu, jeśli chcesz zmienić swoją decyzję, zamknij i otwórz ponownie przeglądarkę',
        not_supported: 'Twój iPhone ma uszkodzony żyroskop lub nie jest wspierany przez aplikację',
      },
    },
    roomsTitle: {
      livingRoom: 'POKÓJ DZIENNY',
      bedroom: 'SYPIALNIA',
      bathroom: 'ŁAZIENKA',
      moveStraight: 'PRZEJDŹ DO PUNKTU',
      childrenRoom: 'POKÓJ',
      kitchen: 'KUCHNIA',
      stairs: 'SCHODY',
      toilet: 'TOALETA',
      bathroom2: 'ŁAZIENKA',
      exterior: 'PRZEJDŹ NA ZEWNĄTRZ',
      exterior2: 'PRZEJDŹ DALEJ',
      dressingRoom: 'GARDEROBA',
      pantry: 'SPIŻARNIA',
      retailSpace: 'LOKAL USŁUGOWY',
      boilerRoom: 'KOTŁOWNIA',
      homeOffice: 'GABINET',
      pool: 'BASEN',
      washingRoom: 'PRALNIA',
      winterGarden: 'OGRÓD ZIMOWY',
      entresol: 'ANTRESOLA',
      attic: 'PODDASZE',
      library: 'BIBLIOTEKA',
      gameRoom: 'POKÓJ DO GIER',
      homeTheater: 'KINO DOMOWE',
      playRoom: 'POKÓJ DLA DZIECI/BAWIALNIA',
      workShop: 'WARSZTAT',
      livingRoomWithAKitchenette: 'SALON Z ANEKSEM KUCHENNYM',
      habitableAttic: 'PODDASZE UŻYTKOWE',
      utilityRoom: 'POMIESZCZENIE GOSPODARCZE',
      service: 'LOKAL USŁUGOWY',
      diningRoom: 'JADALNIA',
    },
  },
  es: {
    language: 'es',
    floor: 'PISO',
    poweredBy: 'Creado por',
    visit: 'Visite nuestra página',
    cookies: 'The application uses cookies. Cookies are used only to keep the application running, analyzing the way the application is used and its individual functionalities and to adjust the application to user preferences and the further development of its functionality, we do not track traffic or profile individual user behavior on the Internet.',
    floor_plan: 'Plano del departamento',
    multi_styles: 'Escoja estilo',
    dollhouse: 'Casa de muñecas',
    share: 'Compartir',
    rate: 'Evalúa el departamento',
    vr_mode: 'Modo Realidad Virtual',
    auto_rotate: 'Auto-rotar',
    gyroscope: 'Giroscopio',
    fullscreen: 'Pantalla completa',
    screenshot: 'Captura de pantalla',
    guide_decoroom: 'El diseño del departamento mostrado ha sido creado en cooperación con la empresa basada en Varsovia, Decoroom Sp z.o.o. y es una propuesta posible de un acomodo y acabados llave en mano de su departamento por Decoroom.',
    guide_sub_decoroom: 'Obtenga más información en ',
    guide_title2: 'El presente recorrido es una visualización',
    guide_title: '¿Cómo usar el recorrido?',
    move_keyboard: 'Da una vista al departamento usando el ratón o las flechas del teclado',
    move_finger: 'Da una vista al departamento desplazando tus dedos en la pantalla o girando el dispositivo',
    next_room: 'Para ver la siguiente habitación, presiona en el ícono blanco',
    loading: 'Cargando',
    disclaimer: 'La información contenida en este sitio y el sitio por si mismo, no constituye una oferta para efectos del código civil',
    something_wrong: 'Algo salió mal',
    error_no_webgl: 'Su dispositivo parece no soportar WebGL. Averigua como obtenerlo.',
    webgl_link: 'aquí',
    error_context_lost: 'Por favir intenta de nuevo refrescando la página de su navegador',
    share_to_social_title: 'Comparte el recorrido del departamento',
    share_to_social_description: 'Comparte el recorrido del departamento con amigos en redes sociales o, ',
    share_to_social_description_span: 'Copia el vínculo',
    rate_apartment_title: 'Qué tanto te gusta este departamento?',
    rate_apartment_description: 'Comparte tu opinión acerca del departamento calificándolo en escala de 1 a 5',
    rate_apartment_thankyou_title: 'Gracias !',
    rate_apartment_thankyou_description: 'Su opinión es muy importante para nosotros,',
    shoppingList: {
      designer: 'DISEÑO INTERIOR POR',
      all: 'Todos',
      more: 'Más',
      generatePDF: 'Genera lista de productos (PDF)',
      downloadPDF: 'Descarga lista de productos (PDF)',
      generatingList: 'Generando lista',
      summaryPrice: 'Precio total de amueblado',
      allSelect: 'Todos (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'Para usar la función de realidad virtual ve a',
        permission_button: 'GIROSCOPIO',
        toggle_gyroscope: 'Puedes prender y apagar el giroscopio en cualquir momento presionando el botón de giroscopio en el fondo de la pantanalla.',
        cancel_permission: 'Recientemente cancelaste el acceso al giroscopio, si quiertes cambiarlo, cierra y abre el navegador.',
        not_supported: 'Tu Iphone tiene un giroscopio dañado o no es soportado por la aplicación',
      },
    },
    roomsTitle: {
      livingRoom: 'SALA',
      bedroom: 'HABITACIÓN',
      bathroom: 'BAÑO',
      moveStraight: 'VE DERECHO',
      childrenRoom: 'CUARTO',
      kitchen: 'COCINA',
      stairs: 'ESCALERAS',
      toilet: 'INODORO',
      bathroom2: 'BAÑO',
      exterior: 'VE AFUERA',
      exterior2: 'IR MÁS ALLÁ',
      dressingRoom: 'VESTIDOR',
      pantry: 'DESPENSA',
      retailSpace: 'ESPACIO DE VENTA AL PORMENOR',
      boilerRoom: 'CUARTO DE CALENTADOR',
      homeOffice: 'OFICINA EN CASA',
      pool: 'PISCINA',
      washingRoom: 'CUARTO DE LAVADO',
      winterGarden: 'JARDÍN DE INVIERNO',
      entresol: 'ENTRESUELO',
      attic: 'ÁTICO',
      library: 'BIBLIOTECA',
      gameRoom: 'SALA DE JUEGOS',
      homeTheater: 'HOME THEATER',
      playRoom: 'SALA DE JUEGOS',
      workShop: 'TALLER',
      livingRoomWithAKitchenette: 'SALÓN CON KITCHENETTE',
      habitableAttic: 'ÁTICO HABITABLE',
      utilityRoom: 'CUARTO DE SERVICIO',
      service: 'SERVICIO',
      diningRoom: 'COMEDOR',
    },
  },
  fr: {
    loaderAnimation: 'Loading...',
    language: 'fr',
    floor: 'Niveau',
    poweredBy: 'Réalisé par',
    visit: 'visiter la page',
    cookies: 'The application uses cookies. Cookies are used only to keep the application running, analyzing the way the application is used and its individual functionalities and to adjust the application to user preferences and the further development of its functionality, we do not track traffic or profile individual user behavior on the Internet.',
    floor_plan: 'PLAN DE L\'APPARTEMENT',
    multi_styles: 'Choisir le style de l\'ambiance',
    dollhouse: 'DOLLHOUSE',
    share: 'VISUALISER',
    rate: 'EVALUER L\'APPARTEMENT',
    vr_mode: 'MODE VR',
    auto_rotate: 'ROTATION AUTOMATIQUE',
    gyroscope: 'GYROSCOPE',
    fullscreen: 'PLEIN ÉCRAN',
    screenshot: 'CAPTURE D\'ÉCRAN',
    guide_decoroom: '"La conception du local présenté a été réalisée en coordination avec l\'Atelier Decoroom S.a.r.l de Varsovie. \n' +
      'Elle représente l’une des possibilités des arrangements décoratifs internes et de finitions de votre futur logement pouvant être réalisés par Decoroom. \n' +
      '"',
    guide_sub_decoroom: 'Pour plus d\'informations, voir ',
    guide_title2: 'Travelling proposé ici est une visualisation ',
    guide_title: 'Comment exploiter la visite virtuelle',
    move_keyboard: 'Se déplacer à l\'aide d\'une souris',
    move_finger: 'Se déplacer au toucher sur l\'écran',
    next_room: 'Pour se déplacer vers une autre pièce, clicker sur son îcone',
    loading: 'Chargement',
    disclaimer: 'Les ambiances décortives ainsi que les visuels internes sont présentés ici à titre indicatif, à caractère non contractuel',
    error_no_webgl: 'Votre équipement ne supporte pas WebGl. A en savoir plus clicker',
    error_context_lost: 'Réessayer à nouveau en actualisant la page',
    something_wrong: 'Une erreur s\'est produite',
    webgl_link: 'ici',
    share_to_social_title: 'Partager la visite virtuelle',
    share_to_social_description: 'partager la visite virtuelle sur des réseaux sociaux ',
    share_to_social_description_span: 'copier le lien',
    rate_apartment_title: 'Comment trouvez vous cet appartement ?',
    rate_apartment_description: 'Partager votre opinion en attribuant la note de 1 - 5',
    rate_apartment_thankyou_title: 'Merci!',
    rate_apartment_thankyou_description: 'Votre avis compte nous est précieux',
    shoppingList: {
      designer: 'Design interne',
      all: 'Toutes, Tous',
      more: 'Plus',
      generatePDF: 'Génerer une liste de produits (PDF)',
      downloadPDF: 'Télécharger la liste de produits (PDF)',
      generatingList: 'Edition de la liste en cours',
      summaryPrice: 'Coût total des équipements ',
      allSelect: 'Toutes, Tous',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'Afin de bénéficier de la fonction de la réalité virtuelle, clicker ici',
        permission_button: 'GYROSCOPE',
        toggle_gyroscope: 'Activer et désactiver le gyroscope à tout moment en clickant sur le bouton situé en bas de l\'écran',
        cancel_permission: 'Après avoir annulé l\'accès au gyroscope, si modification de choix , fermer et rouvrir à nouveau la visionneuse',
        not_supported: 'Le gyroscope de votre Iphone est hors service ou l\'application installée ne peut pas le supporter',
      },
    },
    roomsTitle: {
      livingRoom: 'LE SÉJOUR',
      bedroom: 'CHAMBRE À COUCHER',
      bathroom: 'SALLE DE BAIN',
      moveStraight: 'AVANCER TOUT DROIT',
      childrenRoom: 'CHAMBRE',
      kitchen: 'CUISINE',
      stairs: 'ESCALIER',
      toilet: 'TOILETTES',
      bathroom2: 'SALLE DE BAIN',
      exterior: 'SORTIR',
      exterior2: 'PASSER À L\'ÉTAPE SUIVANTE',
      dressingRoom: 'LE VESTIAIRE',
      pantry: 'CELLIER',
      retailSpace: 'CELLULE COMMERCIALE',
      boilerRoom: 'CHAUFFERIE',
      homeOffice: 'BUREAU',
      pool: 'PISCINE',
      washingRoom: 'LAVERIE',
      winterGarden: 'JARDIN D\'HIVER',
      entresol: 'Entresol',
      attic: 'GRENIER',
      library: 'BIBLIOTHÈQUE',
      gameRoom: 'SALLE DE JEUX',
      homeTheater: 'HOME CINÉMA',
      playRoom: 'SALLE DE JEUX',
      workShop: 'ATELIER',
      livingRoomWithAKitchenette: 'SALON ABEC KITCHENETTE',
      habitableAttic: 'GRENIER HABITABLE',
      utilityRoom: 'LOCAL TECHNIQUE',
      service: 'SERVICE',
      diningRoom: 'SALLE À MANGER',
    },
  },
  de: {
    language: 'de',
    floor: 'STOCKWERK',
    poweredBy: 'Erstellt von',
    visit: 'Besuchen Sie die Website',
    cookies: 'The application uses cookies. Cookies are used only to keep the application running, analyzing the way the application is used and its individual functionalities and to adjust the application to user preferences and the further development of its functionality, we do not track traffic or profile individual user behavior on the Internet.',
    floor_plan: 'WOHNUNGSPLAN',
    multi_styles: 'WÄHLEN SIE EINEN STIL AUS',
    dollhouse: 'PUPPENHAUS',
    share: 'TEILEN',
    rate: 'BEWERTEN SIE DIE WOHNUNG',
    vr_mode: 'VR-MODUS',
    auto_rotate: 'AUTOMATISCH DREHEN',
    gyroscope: 'GYROSKOP',
    fullscreen: 'VOLLBILD',
    screenshot: 'BILDSCHIRMFOTO',
    guide_decoroom: 'Das präsentierte Raumdesign wurde in Zusammenarbeit mit Decoroom sp. z o.o. mit Sitz in Warschau erstellt und ist ein Vorschlag über die mögliche Innenraumgestaltung und Fertigstellung Ihrer schlüsselwertigen Wohnung durch Decoroom.',
    guide_sub_decoroom: 'Weitere Informationen finden Sie unter ',
    guide_title: 'Bedienungsanleitung?',
    guide_title2: 'Der dargestellte Rundgang ist eine Visualisierung',
    move_keyboard: 'Bewegen Sie sich mit der Maus oder den Tastaturpfeilen',
    move_finger: 'Schauen Sie sich in der Wohnung um, indem Sie Ihren Finger über den Bildschirm schieben oder das Gerät drehen',
    next_room: 'Um zum nächsten Raum zu gelangen, klicken Sie auf das entsprechende Symbol',
    loading: 'Ladevorgang ',
    disclaimer: 'Die hier gezeigte Anordnung der Räume ist beispielhaft und die Außenansichten können von den tatsächlichen abweichen. Die Visualisierungen dienen der Veranschaulichung und Information und stellen kein Angebot im Sinne des Gesetzes dar.',
    error_no_webgl: 'Etwas ist schief gelaufen.',
    error_context_lost: 'Ihr Gerät unterstützt WebGL nicht. Finde mehr heraus',
    something_wrong: 'Bitte versuchen Sie es erneut, indem Sie Ihren Browser aktualisieren',
    webgl_link: 'Hier',
    share_to_social_title: 'Rundgang teilen',
    share_to_social_description: 'Teilen Sie den Rundgang mit Ihren Freunden in den sozialen Medien oder, ',
    share_to_social_description_span: 'kopieren Sie den link',
    rate_apartment_title: 'Wie gefällt Ihnen diese Wohnung?',
    rate_apartment_description: 'Bewerten Sie die Wohnung, indem Sie sie auf einer Skala von 1 bis 5 bewerten',
    rate_apartment_thankyou_title: 'Vielen Dank!',
    rate_apartment_thankyou_description: 'Ihre Meinung bedeutet uns sehr viel',
    shoppingList: {
      designer: 'Entwurf der Innenraumgestalltung',
      all: 'Alle',
      more: 'Mehr',
      generatePDF: 'Produktliste erstellen (PDF)',
      downloadPDF: 'Produktliste herunterladen (PDF)',
      generatingList: 'Die Liste wird erstellt',
      summaryPrice: 'Gesamtpreis der Ausstattung',
      allSelect: 'Alle (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'Klicken Sie auf die Schaltfläche unten, um die Virtual-Reality-Funktion zu verwenden',
        permission_button: 'GYROSKOP',
        toggle_gyroscope: 'Sie können das Gyroskop jederzeit ein- und ausschalten, indem Sie auf die Kreiselschaltfläche am unteren Bildschirmrand klicken.',
        cancel_permission: 'Sie haben den Zugriff auf den Gyroskop abgebrochen. Wenn Sie Ihre Entscheidung ändern möchten, schließen Sie den Browser und öffnen ihn erneut.',
        not_supported: 'Ihr iPhone hat ein beschädigtes Gyroskop oder wird von der Anwendung nicht unterstützt.',
      },
    },
    roomsTitle: {
      livingRoom: 'WOHNZIMMER',
      bedroom: 'SCHLAFZIMMER',
      bathroom: 'BADEZIMMER',
      moveStraight: 'GEHE ZUM PUNKT',
      childrenRoom: 'ZIMMER',
      kitchen: 'KÜCHE',
      stairs: 'TREPPE',
      toilet: 'TOILETTE',
      bathroom2: 'BADEZIMMER',
      exterior: 'GEHE NACH DRAUßEN',
      exterior2: 'WEITER',
      dressingRoom: 'KLEIDERSCHRANK',
      pantry: 'VORRATSRAUM',
      retailSpace: 'GESCHÄFTSFLÄCHE',
      boilerRoom: 'HEIZRAUM',
      homeOffice: 'BÜRO',
      pool: 'SCHWIMMBAD',
      washingRoom: 'WASCHKÜCHE',
      winterGarden: 'WINTERGARTEN',
      entresol: 'ZWISCHENGESCHOSS',
      attic: 'DACHBODEN',
      library: 'BIBLIOTHEK',
      gameRoom: 'SPIELZIMMER',
      homeTheater: 'HEIMKINO',
      playRoom: 'SPIELZIMMER',
      workShop: 'WERKSTATT',
      livingRoomWithAKitchenette: 'WOHNZIMMER MIT KÜCHENZEILE',
      habitableAttic: 'BEWOHNBARER DACHBODEN',
      utilityRoom: 'WIRTSCHAFTSRAUM',
      service: 'DIENSTLEISTUNG',
      diningRoom: 'ESSZIMMER',
    },
  },
  it: {
    language: 'it',
    floor: 'LIVELLO',
    poweredBy: 'Powered by',
    visit: 'Visita il nostro sito',
    cookies: '"L\'applicazione utilizza i cookie. I cookie vengono utilizzati solo per mantenere l\'applicazione in esecuzione\'\n' +
      'g, analizzare il modo in cui viene utilizzata l\'applicazione e le sue singole funzionalità e regolare l\'app\n' +
      'in base alle preferenze dell\'utente e all\'ulteriore sviluppo delle sue funzionalità, non tracciamo il traffico\n' +
      'c o profilare il comportamento dei singoli utenti su Internet."',
    floor_plan: 'PIANO TOPO',
    multi_styles: 'SELEZIONA STILE',
    dollhouse: 'DOLLHOUSE',
    share: 'CONDIVIDI',
    rate: 'VALUTA APPARTAMENTO',
    vr_mode: 'VR MODE',
    auto_rotate: 'AUTO ROTAZIONE',
    gyroscope: 'GIRISCOPIO',
    fullscreen: 'FULLSCREEN',
    screenshot: 'SCREENSHOT',
    guide_decoroom: 'La disposizione presentata dei locali è stata creata in collaborazione con lo studio d\'architettura ed è un esempio di finitura chiavi in ​​mano che puoi utilizzare per organizzare il tuo appartamento',
    guide_sub_decoroom: 'Per maggiori informazioni, vedi ',
    guide_title2: 'Il tour è in visualizzazione',
    guide_title: 'Come usare il tour?',
    move_keyboard: 'Naviga appartamento usando mouse',
    move_finger: 'Naviga appartamento con il dito sullo schermo',
    next_room: 'Per vedere la prossima stanza, clicca sull\'icona bianca',
    loading: 'Caricamento',
    disclaimer: 'Le informazioni contenute nel sito non costituiscono un\'offerta ufficiale',
    something_wrong: 'Qualcosa è andato storto',
    error_no_webgl: 'Il tuo dispositivo non supporta WebGL',
    webgl_link: 'qua',
    error_context_lost: 'Prova ancora, effettua refresh del browser',
    share_to_social_title: 'Condividi tour appartamento',
    share_to_social_description: 'Condividi tour appartamento con amici o sui social o ',
    share_to_social_description_span: 'copia link',
    rate_apartment_title: 'Ti piace l\'appartamento?',
    rate_apartment_description: 'Condividi la tua opinione sull\'appartamento da una scala da 1 a 5',
    rate_apartment_thankyou_title: 'Grazie!',
    rate_apartment_thankyou_description: 'La tua opinione per noi è importante',
    shoppingList: {
      designer: 'INTERIOR DESIGN',
      all: 'All',
      more: 'More',
      generatePDF: 'Generate a list of products (PDF)',
      downloadPDF: 'Download product list (PDF)',
      generatingList: 'Generating list',
      summaryPrice: 'The total price of the equipment',
      allSelect: 'All (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'Per utilizzare la realtà virtuale, clicca sul bottone sottostante',
        permission_button: 'GIROSCOPIO',
        toggle_gyroscope: 'Puoi attivare o disattivare il giroscopio in ogni momento cliccando sul bottone relativo',
        cancel_permission: 'Hai disattivato l\'accesso al giroscopio',
        not_supported: 'Il tuo telefono è danneggiato oppure non supporta l\'applicazione giroscopio',
      },
    },
    roomsTitle: {
      livingRoom: 'SOGGIORNO',
      bedroom: 'CAMERA DA LETTO',
      bathroom: 'BAGNO',
      moveStraight: 'VAI AVANTI',
      childrenRoom: 'STANZA',
      kitchen: 'CUCINA',
      stairs: 'SCALE',
      toilet: 'BAGNO',
      bathroom2: 'BAGNO',
      exterior: 'ESCI',
      exterior2: 'AVANTI',
      dressingRoom: 'GUARDAROBA',
      pantry: 'DISPENSA',
      retailSpace: 'POSIZIONE DI SERVIZIO',
      boilerRoom: 'CALDAIA',
      homeOffice: 'UFFICIO',
      pool: 'PISCINA',
      washingRoom: 'LAVANDERIA',
      winterGarden: 'GIARDINO D\'INVERNO',
      entresol: 'ENTRESOL',
      attic: 'SOFFITTA',
      library: 'BIBLIOTECA',
      gameRoom: 'SALA GIOCHI',
      homeTheater: 'HOME THEATER',
      playRoom: 'SALA GIOCHI',
      workShop: 'OFFICINA',
      livingRoomWithAKitchenette: 'SOGGIORNO CON ANGOLO COTTURA',
      habitableAttic: 'SOFFITTA ABITABILE',
      utilityRoom: 'LOCALE TECNICO',
      service: 'SERVIZIO',
      diningRoom: 'SALA DA PRANZO',
    },
  },
  he: {
    language: 'he',
    floor: 'קומה',
    poweredBy: 'נוצר ע"י',
    visit: 'בקרו באתר',
    cookies: 'אתר זה משתמש בקבצי cookie, שייכים לנו או לצדדים שלישיים, לצורכי ביצועים ומציגים שמפרסמים להעדפות הגלישה שלך וזה עשוי לעניין אותך. כדי להבין יותר אודות האופן בו אנו משתמשים בקובצי cookie או לשנות את העדפות העוגיות שלך, לחץ על "הגדרות קובצי Cookie". תוכל לשנות הגדרות אלה בכל עת על ידי ביקור במדיניות העוגיות שלנו ובעקבות ההוראות שהיא מכילה. בלחיצה על "קבל את כל העוגיות" אתה מסכים לאחסון העוגיות במכשיר שלך.',
    floor_plan: 'תוכנית קומה',
    multi_styles: 'בחר עיצוב',
    dollhouse: 'מודל להמחשה',
    share: 'שתף',
    rate: 'דרג דירה',
    vr_mode: 'הפעל VR',
    auto_rotate: 'סיבוב אוטומטי',
    gyroscope: 'ג\'ירוסקופ',
    fullscreen: 'מסך מלא',
    screenshot: 'צילום מסך',
    guide_decoroom: 'עיצוב הדירות המוצגות נוצר בשיתוף פעולה עם Decoroom Sp. z o.o. והיא מהווה הצעה לעיצוב אפשרי וגימור של הדירה שלך על ידי Decoroom.',
    guide_sub_decoroom: 'למידע נוסף, ראה ',
    guide_title2: 'הסיור המוצג הוא הדמיה',
    guide_title: 'כיצד להשתמש בסיור הווירטואלי',
    move_keyboard: 'הבט סביב ע"י שימוש בעכבר או חיצי המקלדת',
    move_finger: 'הבט סביב ע"י מעבר עם האצבע על המסך או היפוך מסך ',
    next_room: 'כדי לראות את החדר הבא  לחץ על כפתור הלבן',
    loading: 'טוען,',
    disclaimer: 'המידע הכלול באתר ובאתר עצמו אינו מהווה הצעה להקוד האזרחי',
    something_wrong: 'המכשיר שלך אינו תומך בWebGL,  גלה איך לבצע עדכון',
    error_no_webgl: 'אנא רענן את הדפדפן',
    webgl_link: 'כאן,',
    error_context_lost: 'משהו השתבש',
    share_to_social_title: 'שתף את הסיור הווירטואלי',
    share_to_social_description: 'שתף את הסיור הווירטואלי עם חברים ברשת החברתית ',
    share_to_social_description_span: 'העתק קישור,',
    rate_apartment_title: 'מה דעתך על הדירה הזו?',
    rate_apartment_description: 'נשמח לדירוג הדירה  ולשמוע מה דעתך',
    rate_apartment_thankyou_title: 'תודה רבה!',
    rate_apartment_thankyou_description: 'דעתך חשובה לנו,',
    shoppingList: {
      designer: 'עיצוב פנים ע"י',
      all: 'הכל',
      more: 'הצג עוד',
      generatePDF: 'צור רשימת מוצרים (PDF)',
      downloadPDF: 'הורד את רשימת המוצרים (PDF)',
      generatingList: 'יוצר רשימה',
      summaryPrice: 'ריהוט מחיר כולל',
      allSelect: 'הכל',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'כדי להשתמש בסיור הווירטואלי לך ל',
        permission_button: 'ג\'ירוסקופ',
        toggle_gyroscope: 'אתה יכול להפעיל ולכבות את הג\'ירוסקופ בכל עת על ידי לחיצה על כפתור הג\'ירוסקופ בתחתית המסך,',
        cancel_permission: 'לאחרונה ביטלת את הגישה לג\'ירוסקופ, אם אתה רוצה לשנות את זה, סגור ופתח מחדש את הדפדפן,',
        not_supported: 'למכשיר שלך יש ג\'ירוסקופ פגום או שהוא לא נתמך על ידי האפליקציה,',
      },
    },
    roomsTitle: {
      livingRoom: 'סלון',
      bedroom: 'חדר שינה',
      bathroom: 'חדר מקלחת',
      moveStraight: 'המשך ישר',
      childrenRoom: 'חדר',
      kitchen: 'מטבח',
      stairs: 'מדרגות',
      toilet: 'שירותים',
      bathroom2: 'מקלחת',
      exterior: 'מעבר לבחוץ',
      exterior2: 'המשך',
      dressingRoom: 'חדר הלבשה',
      pantry: 'מזוה',
      retailSpace: 'שטח קמעונאי',
      boilerRoom: 'חדר דוד',
      homeOffice: 'משרד בייתי',
      pool: 'בריכה',
      washingRoom: 'חדר כביסה',
      winterGarden: 'גן חורף',
      entresol: 'אנטרסול',
      attic: 'עליית גג',
      library: 'ספרייה',
      gameRoom: 'חדר משחקים',
      homeTheater: 'קולנוע ביתי',
      playRoom: 'חדר משחקים',
      workShop: 'סדנה',
      livingRoomWithAKitchenette: 'סלון עם מטבחון',
      habitableAttic: 'עליית גג שמשמשת למגורים',
      utilityRoom: 'חדר שירות',
      service: 'שירות',
      diningRoom: 'פינת אוכל',
    },
  },
  bg: {
    language: 'bg',
    floor: 'Етаж',
    poweredBy: 'Създадено от',
    visit: 'Посетете страницата ни',
    cookies: 'Приложението използва бисквитки". "Бисквитките" се използват само за поддържане на работата на приложението, за анализиране на начина на използване на приложението и неговите индивидуални функционалности. "Бисквитките" не се използват за проследяване на трафика както и за профилирането на индивидуалното поведение на потребителите в интернет.',
    floor_plan: 'План етаж',
    multi_styles: 'Изберете дизайн',
    dollhouse: '3D Модел',
    share: 'Споделете',
    rate: 'Оценете апартамента',
    vr_mode: 'VR mode',
    auto_rotate: 'Автоматично въртене',
    gyroscope: 'Жироскоп',
    fullscreen: 'Цял екран',
    screenshot: 'Снимка на екрана',
    guide_decoroom: 'Интериорният дизайн е създаден съвместно с Decoroom Sp. Варшава и е идеен проект за възможно обзавеждане и довършване на апартамента. Дизайнът е представен с илюстративна цел.',
    guide_sub_decoroom: 'За повече информация вж ',
    guide_title2: 'Виртуалната разходка е с представтиелна цел',
    guide_title: 'Как да използвате виртиуалната разходка?',
    move_keyboard: 'Разгледайта апартамента като използвате мишката или стрелките на клавиатурата',
    move_finger: 'Разгледайта апартамента като преплъзвате пръста си по екрана на устройството, което използвате',
    next_room: 'Кликнете на бялата икона, за да разгледате следващата стая',
    loading: 'Зареждане',
    disclaimer: 'Информацията, съдържаща се в сайта и самият сайт не представляват оферта по закона за гражданаското право',
    error_no_webgl: 'Устройството ви не поддържа WebGL. Научете повече за това как може да го свалите.',
    error_context_lost: 'Натиснете Refresh на вашия браузер и опитайте отново.',
    something_wrong: 'Възникна грешка.',
    webgl_link: 'тук',
    share_to_social_title: 'Споделете разходката на апартамента',
    share_to_social_description: 'Споделете разходката на апартамента с ваши приятели в социалните мрежи ',
    share_to_social_description_span: 'копирайте линка',
    rate_apartment_title: 'Може ли да оцените до каква степен ви харесва този апартамент?',
    rate_apartment_description: 'Споделете вашето мнение за апартамента като изберете рейтинг от 1 до 5.',
    rate_apartment_thankyou_title: 'Благодарим ви!',
    rate_apartment_thankyou_description: 'Вашето мнение е от огромно значение за нас.',
    shoppingList: {
      designer: 'ИНТЕРИОРЕН ДИЗАЙН ОТ',
      all: 'всичко',
      more: 'Повече',
      generatePDF: '',
      downloadPDF: 'Още Изтеглете списък с продукти (PDF)',
      generatingList: 'Генериране на списък',
      summaryPrice: 'Обзавеждане обща цена',
      allSelect: 'всичко (0)',
    },
    gyroscope_guides: {
      ios13: {
        gyroscope_usage: 'За да използвате функцията за  виртуална реалност, кликнете на бутона отдолу',
        permission_button: 'Жироскоп',
        toggle_gyroscope: 'Може да активирате или деактивирате жироскопа по всяко време като кликнете на бутона за жироскоп най-отдолу на екрана.',
        cancel_permission: 'Отменихте функцията на жироскопа, ако искате да направите промяна затворете и отворите отново браузера.',
        not_supported: 'Жироскопът на вашия iPhone не работи или не се поддържа от апликацията.',
      },
    },
    roomsTitle: {
      livingRoom: 'Дневна',
      bedroom: 'Спалня',
      bathroom: 'Баня',
      moveStraight: 'Напред',
      childrenRoom: 'Детска стая ',
      kitchen: 'Кухня',
      stairs: 'Стълби',
      toilet: 'Тоалетна',
      bathroom2: 'Баня',
      exterior: 'Излизане навън',
      exterior2: 'Продълже напред',
      dressingRoom: 'Дрешник',
      pantry: 'Килер',
      retailSpace: 'Услуги',
      boilerRoom: 'Помещение за бойлер',
      homeOffice: 'Офис кабинет',
      pool: 'Басейн',
      washingRoom: 'Помещение за пералня',
      winterGarden: 'Зимна градина',
      entresol: 'Антре',
      attic: 'Мансарда',
      library: 'Библиотека',
      gameRoom: 'Стая за игри',
      homeTheater: 'Домашно кино',
      playRoom: 'Стая за игри',
      workShop: 'Уъркшоп',
      livingRoomWithAKitchenette: 'Дневна с кухня',
      habitableAttic: 'Обитаема мансарда',
      utilityRoom: 'Помощне помещение',
      service: 'Услуги',
      diningRoom: 'Трапезария',
    },
  },
};
